import React, { FC } from 'react';
import YouTube, { Options } from 'react-youtube';

import { YouTubeLazyLoadedProps } from './models';

import './YouTubeLazyLoaded.scss';

const YouTubeLazyLoaded: FC<YouTubeLazyLoadedProps> = ({ videoId, videoName }) => {
  const [startYoutube, setState] = React.useState(false);
  const setStartYoutube = () => {
    setState(true);
  };

  const videoOpts: Options = {
    playerVars: {
      controls: 1,
      autoplay: 1,
      loop: 1,
      showinfo: 0,
      modestbranding: 1,
    },
  };

  return (
    <div className="video-wrapper">
      {startYoutube ? (
        <YouTube videoId={videoId} opts={videoOpts} />
      ) : (
        <div role="button" onClick={setStartYoutube} onKeyDown={setStartYoutube} tabIndex={0}>
          <img
            src={`https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`}
            loading="lazy"
            className="img-fluid w-100"
            alt={videoName}
          />
          <i className="fab fa-youtube" />
        </div>
      )}
    </div>
  );
};

export default YouTubeLazyLoaded;
